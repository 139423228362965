<template>
  <div id="mian">
    <div class="header">
      <span @click="toTypeUrl('applet_list_detail')">基本信息</span>
      <span id="fzcolor">配置信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">配置信息</h5>
    </div>
    <!-- 复用创建或快速创建 -->
    <!--  v-if="detail.appType == 'QUICK_CREATE' || detail.appType == 'REUSE'" -->
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 171px">
            <p>条目</p>
          </td>
          <td style="width: 163px">
            <p>详情</p>
          </td>
          <td style="width: 187px">
            <p>状态</p>
          </td>
          <td style="width: 95px">
            <p>状态描述</p>
          </td>
          <td style="width: 95px">
            <p>操作</p>
          </td>
        </tr>
        <!-- 认证状态 -->
        <tr>
          <td style="width: 171px">
            <p>认证状态</p>
          </td>
          <td style="width: 163px">
            <p>{{ detail.appId }}</p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.verifyStatus ? "已认证" : "未认证" }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ !detail.verifyStatus ? detail.verifyFailReason : "成功" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i :class="detail.verifyStatus ? 'over' : ''" class="lianjie" @click="setReverifyShow">修改</i>
            </p>
          </td>
        </tr>

        <!-- 小程序名称 -->
        <tr>
          <td style="width: 171px">
            <p>小程序名称</p>
          </td>
          <td style="width: 163px">
            <p>{{ detail.appName ? detail.appName : "" }}</p>
          </td>
          <td style="width: 187px">
            <p>{{ appNameStatus[detail.appNameStatus] }}</p>
          </td>
          <td style="width: 95px">
            <p class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.appNameStatus == 'SUCCESS'
                  ? '成功'
                  : detail.appNameStatus == 'PENDING'
                    ? '审核中'
                    : detail.appNameStatus == null
                      ? ''
                      : detail.appNameFailReason
                " placement="top">
                <span>{{
                  detail.appNameStatus == "SUCCESS"
                    ? "成功"
                    : detail.appNameStatus == "PENDING"
                      ? "审核中"
                      : detail.appNameStatus == null
                        ? ""
                        : detail.appNameFailReason
                }}</span>
              </el-tooltip>
            </p>
            <!-- <p>
              {{
                detail.appNameStatus == "SUCCESS"
                  ? "成功"
                  : detail.appNameStatus == "PENDING"
                  ? "审核中"
                  : detail.appNameStatus == null
                  ? ""
                  : detail.appNameFailReason
              }}
            </p> -->
          </td>
          <td style="width: 95px">
            <p>
              <i :class="detail.verifyStatus ? '' : 'over'" class="lianjie"
                @click="detail.verifyStatus ? editTabShow('APPNAME') : ''">修改</i>
              <i :class="detail.verifyStatus ? '' : 'over'" class="lianjie"
                @click="detail.verifyStatus ? sync('APPNAME_STATUS') : ''">同步</i>
            </p>
          </td>
        </tr>

        <!-- 小程序头像 -->
        <tr>
          <td style="width: 171px">
            <p>小程序头像</p>
          </td>
          <td style="width: 163px">
            <p>
              <span v-if="!detail.avatar"></span>
              <img v-else :src="detail.avatar" alt="加载失败" />
            </p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.avatarStatus ? "已通过" : "未通过" }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ !detail.avatarStatus ? detail.avatarFailReason : "成功" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? editTabShow('AVATAR') : ''">
                修改</i>
            </p>
          </td>
        </tr>

        <!-- 介绍 -->
        <tr>
          <td style="width: 171px">
            <p>介绍</p>
          </td>
          <td style="width: 163px">
            <p>{{ detail.signature ? detail.signature : "" }}</p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.signatureStatus ? "已通过" : "未通过" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              {{
                !detail.signatureStatus ? detail.signatureFailReason : "成功"
              }}
            </p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? editTabShow('SIGNATURE') : ''">修改</i>
            </p>
          </td>
        </tr>

        <!-- 服务类目 -->
        <tr>
          <td style="width: 171px">
            <p>服务类目</p>
          </td>

          <td style="width: 163px">
            <p>
              {{ detail.firstCategory ? detail.firstCategory : ""
              }}{{ detail.secondCategory ? "/" + detail.secondCategory : ""
              }}{{ detail.thirdCategory ? "/" + detail.thirdCategory : "" }}
            </p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.categoryStatus ? "已通过" : "未通过" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              {{ !detail.categoryStatus ? detail.categoryFailReason : "成功" }}
            </p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? editTabShow('CATEGORY') : ''">修改</i>
            </p>
          </td>
        </tr>

        <!-- 代码上传 -->
        <tr>
          <td style="width: 171px">
            <p>代码上传</p>
          </td>

          <td style="width: 163px">
            <p>
              {{ detail.appVersion ? detail.appVersion : "" }}
            </p>
          </td>
          <td style="width: 187px">
            <p v-if="detail.commitStatus == 1">已上传</p>
            <p v-if="detail.commitStatus == 2">未上传</p>
            <p v-if="detail.commitStatus == 0">上传失败</p>
          </td>
          <td style="width: 95px">
            <p v-if="detail.commitStatus == 1">上传成功</p>
            <p v-if="detail.commitStatus == 2">未上传</p>
            <p v-if="detail.commitStatus == 0">上传失败</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? editTabShow('COMMIT') : ''">提交</i>
            </p>
          </td>
        </tr>

        <!-- 体验者设置 -->
        <tr v-if="detail.commitStatus == 1">
          <td style="width: 171px">
            <p>体验者设置</p>
          </td>
          <td style="width: 163px">
            <p class="hidden1" style="width: 250px">
              <el-tooltip class="item" effect="light" :content="detail.users" placement="top">
                <span>{{ detail.users }}</span>
              </el-tooltip>
            </p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.users ? "已设置" : "未设置" }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ detail.users ? "已设置" : "未设置" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="getQrCode">获取体验版二维码</i>
              <i class="lianjie" @click="editTabShow('EXPERIEN')">设置体验者</i>
            </p>
          </td>
        </tr>

        <!-- 代码审核 -->
        <tr>
          <td style="width: 171px">
            <p>代码审核</p>
          </td>
          <td style="width: 163px">
            <!-- <p>{{ detail.appVersion ? detail.appVersion : "" }}</p> -->
            <p v-if="detail.auditVersion || detail.appVersion">
              {{
                detail.auditVersion
                  ? "已审核版本号" + detail.auditVersion
                  : "已审核版本号" + "-"
              }},{{
                detail.appVersion
                  ? "当前审核代码版本" + detail.appVersion
                  : "当前审核代码版本" + "-"
              }}
            </p>
          </td>
          <td style="width: 187px">
            <p>{{ auditStatus[detail.auditStatus] }}</p>
          </td>
          <td style="width: 95px">
            <p class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.auditStatus == 'SUCCESS'
                  ? '成功'
                  : detail.auditStatus == 'PENDING'
                    ? '审核中'
                    : detail.auditStatus == null
                      ? ''
                      : detail.auditFailReason
                " placement="top">
                <span>{{
                  detail.auditStatus == "SUCCESS"
                    ? "成功"
                    : detail.auditStatus == "PENDING"
                      ? "审核中"
                      : detail.auditStatus == null
                        ? ""
                        : detail.auditFailReason
                }}</span>
              </el-tooltip>
            </p>

            <!-- <p>
              {{
                detail.auditStatus == "SUCCESS" ? "成功" : detail.auditStatus == "PENDING"? "审核中": detail.auditStatus == null? "": detail.auditFailReason
              }}
            </p> -->
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? editTabShow('AUDIT') : ''">提交</i>

              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? sync('AUDIT_STATUS') : ''">同步</i>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? exitTypeID() : ''">撤销</i>
            </p>
          </td>
        </tr>

        <!-- 代码部署 -->
        <tr>
          <td style="width: 171px">
            <p>代码部署</p>
          </td>
          <td style="width: 163px">
            <p>
              {{
                detail.deployVersion
                  ? "已部署代码版本" +
                  detail.deployVersion +
                  "," +
                  "当前部署版本" +
                  detail.appVersion
                  : ""
              }}
            </p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.deployStatus ? "已部署" : "未部署" }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ detail.deployStatus ? "成功" : "未部署" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? okTypeCode() : ''">部署</i>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? exitTypeCode() : ''">回退</i>
              <!-- 已部署可以设置规则  -->
              <i class="lianjie" :class="detail.deployStatus ? '' : 'over'"
                @click="detail.deployStatus ? saveRules() : ''">设置规则</i>
            </p>
          </td>
        </tr>

        <!-- 隐私设置 -->
        <tr>
          <td style="width: 171px">
            <p>隐私设置</p>
          </td>
          <td style="width: 163px">
            <p>{{ privacyDetails }}</p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.changeStatus == 2 ? "未设置" : "已设置" }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ detail.changeStatus == 2 ? "未设置" : "成功" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? editTabShow('SEARCH') : ''">修改</i>
              <i class="lianjie" :class="detail.verifyStatus ? '' : 'over'"
                @click="detail.verifyStatus ? setSync() : ''">同步</i>
            </p>
          </td>
        </tr>

        <!-- 半屏小程序 -->
        <tr>
          <td style="width: 171px">
            <p>半屏小程序设置</p>
          </td>
          <td style="width: 163px">
            <p></p>
          </td>
          <td style="width: 187px">
            <p></p>
          </td>
          <td style="width: 95px">
            <p></p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="delAuthorizeModel">取消授权</i>
              <i class="lianjie" @click="setAuthorizeModel">设置授权</i>
              <i class="lianjie" @click="getOwnListShowModel">查询授权列表</i>
            </p>
          </td>
        </tr>


      </table>
    </div>

    <!-- <div class="tab1" v-show="detail.appType == 'MERCHANT'">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 171px">
            <p>条目</p>
          </td>
          <td style="width: 163px">
            <p>详情</p>
          </td>
          <td style="width: 187px">
            <p>状态</p>
          </td>
          <td style="width: 95px">
            <p>状态描述</p>
          </td>
          <td style="width: 95px">
            <p>操作</p>
          </td>
        </tr>
        <tr>
          <td style="width: 171px">
            <p>认证状态</p>
          </td>
          <td style="width: 163px">
            <p>{{ detail.appVersion ? detail.appVersion : "" }}</p>
          </td>
          <td style="width: 187px">
            <p>{{ detail.verifyStatus ? "已认证" : "未认证" }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ !detail.verifyStatus ? detail.verifyFailReason : "成功" }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i
                class="lianjie"
                @click="overType"
                :class="detail.verifyStatus ? 'over' : ''"
                >认证</i
              >
            </p>
          </td>
        </tr>
        <tr>
          <td style="width: 171px">
            <p>代码部署</p>
          </td>
          <td style="width: 163px">
            <p>{{ detail.appVersion ? "部署版本" + detail.appVersion : "" }}</p>
          </td>
          <td style="width: 187px">
            <p>
              {{
                detail.rollbackStatus
                  ? "已撤销"
                  : detail.deployStatus
                  ? "已部署"
                  : "未部署"
              }}
            </p>
          </td>
          <td style="width: 95px">
            <p>
              {{
                detail.deployStatus || detail.rollbackStatus ? "成功" : "未部署"
              }}
            </p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="overType2" v-if="!detail.deployStatus"
                >部署</i
              >
              <i class="lianjie" @click="overType2" v-else>撤销</i>
            </p>
          </td>
        </tr>
      </table>
    </div> -->

    <!-- 认证状态 -->
    <el-dialog title="修改认证状态" :visible.sync="editStatusShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div v-if="detail.appType !== 'REUSE'" class="overAccount_ul_title">
            <i>*</i>企业代码类型
          </div>
          <el-select v-if="detail.appType !== 'REUSE'" v-model="reverifyForm.enterpriseCodeType" placeholder="选择企业代码类型">
            <el-option label="统一社会信用代码" value="1"></el-option>
            <el-option label="组织机构代码" value="2"></el-option>
            <el-option label="营业执照注册号" value="3"></el-option>
          </el-select>

          <div v-if="detail.appType !== 'REUSE'" class="overAccount_ul_title">
            <i>*</i>法人姓名
          </div>
          <el-input v-if="detail.appType !== 'REUSE'" v-model="reverifyForm.legalPersonName" maxlength="128"
            placeholder="输入法人姓名"></el-input>
          <div v-if="detail.appType !== 'REUSE'" class="overAccount_ul_title">
            <i>*</i>法人微信
          </div>
          <el-input v-if="detail.appType !== 'REUSE'" v-model="reverifyForm.legalPersonWechat" maxlength="128"
            placeholder="输入法人微信"></el-input>
          <div v-if="detail.appType === 'REUSE'" class="overAccount_ul_title">
            <i>*</i>所属第三方
          </div>
          <el-select v-if="detail.appType === 'REUSE'" v-model="reverifyForm.thirdId" placeholder="选择所属第三方">
            <el-option v-for="(v, i) in thirdsDrop" :key="i" :label="v.thirdName" :value="v.thirdId"></el-option>
          </el-select>
        </li>
        <li class="ul-right">
          <div v-if="detail.appType !== 'REUSE'" class="overAccount_ul_title">
            <i>*</i>所属第三方
          </div>
          <el-select v-if="detail.appType !== 'REUSE'" v-model="reverifyForm.thirdId" placeholder="选择所属第三方">
            <el-option v-for="(v, i) in thirdsDrop" :key="i" :label="v.thirdName" :value="v.thirdId"></el-option>
          </el-select>
          <div v-if="detail.appType !== 'REUSE'" class="overAccount_ul_title">
            <i>*</i>企业代码
          </div>
          <el-input v-if="detail.appType !== 'REUSE'" v-model="reverifyForm.enterpriseCode" maxlength="128"
            placeholder="输入企业代码"></el-input>
          <div v-if="detail.appType === 'REUSE'" class="overAccount_ul_title">
            <i>*</i>公众号APPID
          </div>
          <el-input v-if="detail.appType === 'REUSE'" v-model="reverifyForm.appId" maxlength="128"
            placeholder="输入公众号APPID"></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="editStatusShow = false">取 消</el-button>
        <el-button class="addBt" @click="setReverify">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改小程序名称弹框 -->
    <el-dialog title="修改小程序名称" :visible="formData.statusType == 'APPNAME'" @close="close" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>小程序名称</div>
          <el-input v-model="formData.appName" maxlength="128" placeholder="输入小程序名称"></el-input>
          <div>
            <a href="javascript:;" @click="isName" class="lianjie" style="font-size: 12px">查询名称是否可用?</a>
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="formData.statusType = ''">取 消</el-button>
        <el-button class="addBt" @click="editTab">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改小程序头像弹框 -->
    <el-dialog title="修改小程序头像" :visible="formData.statusType == 'AVATAR'" @close="close" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 178px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>小程序头像</div>
          <el-upload drag class="avatar-uploader" :before-upload="beforeUpload" :action="uploadUrl"
            :show-file-list="false" :on-success="avatarSuccess">
            <img v-if="formData.avatar" :src="formData.avatar" alt="图片加载失败" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span v-if="formData.avatar" class="avatar-uploader-span" @click.stop="formData.avatar = ''"><i
                class="el-icon-close"></i></span>
          </el-upload>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="close">取 消</el-button>
        <el-button class="addBt" @click="editTab">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改介绍弹框 -->
    <el-dialog title="修改功能介绍" :visible="formData.statusType == 'SIGNATURE'" @close="close" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 300px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>介绍</div>
          <el-input type="textarea" :autosize="{ minRows: 4 }" maxlength="128" v-model="formData.signature"
            placeholder="输入功能介绍"></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="close">取 消</el-button>
        <el-button class="addBt" @click="editTab">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改服务类目弹框 -->
    <el-dialog title="修改服务类目" :visible="formData.statusType == 'CATEGORY'" @close="close" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>一级类目</div>

          <el-select v-model="formData.oneId" @change="cateChange(1)" placeholder="选择一级类目">
            <el-option v-for="v in firstData" :key="v.id" :label="v.name" :value="v.id"></el-option>
          </el-select>
          <!-- <div class="overAccount_ul_title">三级类目</div>
          <el-select
            v-model="formData.thirdCategory"
            placeholder="选择三级类目"
          >
            <el-option
              v-for="v in thirdCategory[secondCategoryId]
                ? thirdCategory[secondCategoryId]
                : []"
              :key="v.id"
              :label="v.name"
              :value="v.name"
            ></el-option>
          </el-select> -->
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>二级类目</div>

          <el-select v-model="formData.twoId" @change="cateChange(2)" placeholder="选择二级类目">
            <el-option v-for="v in secondData" :key="v.id" :label="v.name" :value="v.id"></el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="close">取 消</el-button>
        <el-button class="addBt" @click="editTab">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 代码上传弹框 -->
    <el-dialog title="提交小程序版本号" :visible="formData.statusType == 'COMMIT'" @close="close" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>小程序版本号</div>
          <el-input v-model="formData.appVersion" maxlength="128" placeholder="输入小程序版本号"></el-input>
        </li>
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>模板ID</div>
          <el-input v-model="formData.templateId" maxlength="128" placeholder="输入模板ID"></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="close">取 消</el-button>
        <el-button class="addBt" @click="editTab">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 上传代码审核弹框 -->
    <el-dialog title="代码审核" :visible="formData.statusType == 'AUDIT' || formData.statusType == 'SPEEDUPAUDIT'
      " @close="close" width="700px">
      <ul class="overAccount_ul">
        <li style="margin: 0 20px">
          <div class="overAccount_ul_title">页面截图</div>

          <el-upload size="small" list-type="picture-card" drag :limit="8" :action="uploadUrl"
            accept=".jpg,.jpeg,.png,jpeg,jif" :show-file-list="true" :before-upload="beforeUpload"
            :file-list="imageUrls" :on-success="auditImgSuccess" :on-preview="handlePictureCardPreview"
            :on-remove="handleImgRemove">
            <i class="el-icon-plus"></i>
          </el-upload>

          <el-dialog :visible.sync="dialogVisible" :modal="false">
            <img width="100%" :src="imageUrls" alt="" />
          </el-dialog>
        </li>
        <li style="margin: 0 20px">
          <div class="overAccount_ul_title">操作录屏</div>
          <el-upload size="small" drag ref="uploadVideo" :before-upload="beforeUploadVideo" :action="uploadUrl"
            :show-file-list="false" :on-success="handleVideoSuccess" :on-progress="uploadVideoProcess"
            :on-change="handleChange">
            <i v-if="formData.videoUrl == '' && videoFlag == false" style="font-size: 28px" class="el-icon-plus"></i>
            <video v-if="formData.videoUrl != '' && videoFlag == false" :src="formData.videoUrl" class="videoBox"
              controls="controls">
              您的浏览器不支持视频播放
            </video>
            <div class="progressBox">
              <el-progress v-if="videoFlag" type="circle" :width="148" :percentage="videoUploadPercent"
                style="margin-top: 30px"></el-progress>
            </div>
          </el-upload>
        </li>
        <li style="margin: 0 20px">
          <div class="overAccount_ul_title">
            <span>当月分配可提审次数:</span>
            <span>{{ countForm.limit }}</span>
          </div>
          <div class="overAccount_ul_title">
            <span> 剩余可提审次数:</span>
            <span>{{ countForm.rest }}</span>
          </div>

          <div class="overAccount_ul_title">
            <span>当月分配加急次数: </span>
            <span>{{ countForm.speedup_limit }}</span>
          </div>
          <div class="overAccount_ul_title">
            <span>剩余加急次数:</span>
            <span>{{ countForm.speedup_rest }}</span>
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="close">取 消</el-button>
        <el-button class="addBt" :disabled="disableConfir" @click="editTab">普通审核</el-button>
        <el-button class="addBt" :disabled="disableConfir" @click="expeditedReview">加急审核</el-button>
        <el-button class="addBt" :disabled="disableConfir" @click="getCount">查询次数</el-button>
      </span>
    </el-dialog>

    <!-- 修改隐私设置弹框 -->
    <el-dialog title="修改隐私设置" :visible="formData.statusType == 'SEARCH'" @close="close" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>小程序状态</div>
          <el-select :value="appletStatusName" @change="changeAppletStatus">
            <el-option v-for="v in appletStatusList" :key="v.id" :label="v.label" :value="v.value"></el-option>
          </el-select>
        </li>
      </ul>

      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="close">取 消</el-button>
        <el-button class="addBt" @click="editTab">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 设置体验者弹框 -->
    <el-dialog title="添加体验者" :visible="formData.statusType == 'EXPERIEN'" @close="close" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>体验者微信号</div>
          <el-input v-model="formData.wechatid" maxlength="128" placeholder="输入微信号"></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="formData.statusType = ''">取 消</el-button>
        <el-button class="addBt" :disabled="disableConfir" @click="saveUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除半屏小程序 -->
    <el-dialog :visible.sync="delAuthorizeShow" title="取消授权半屏小程序" width="490px" @close="close">
      <ul class="overAccount_ul">
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>半屏小程序appid</div>
          <el-input v-model="embedData.appid" maxlength="128" placeholder="输入半屏小程序appid"></el-input>
        </li>
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>半屏小程序授权方式</div>
          <el-select v-model="embedData.flag">
            <!-- 0表示需要管理员验证；1表示自动通过；2表示自动拒绝 -->
            <el-option label="管理员验证" value="0"></el-option>
            <el-option label="自动通过" value="1"></el-option>
            <el-option label="自动拒绝" value="2"></el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="delAuthorizeShow = false">取 消</el-button>
        <el-button class="addBt" @click="delAuthorizeBtn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 半屏小程序授权列表  -->
    <el-dialog :visible.sync="getOwnListShow" title="半屏小程序授权列表" width="980px" @close="close">
      <div style="padding: 20px;box-sizing: border-box;">
        <div class="con_from">
          <div class="lineP">
            <div class="item">
              <i>分页起始值</i>
              <el-input v-model="embedListData.start" maxlength="128" placeholder="分页起始值" type="number" min="0"
                max="1000" onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"></el-input>
            </div>
            <div class="item">
              <i>分页结束值</i>
              <el-input v-model="embedListData.num" maxlength="128" placeholder="分页结束值" type="number" min="10"
                max="1000" onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"></el-input>
            </div>
            <div class="itembtn">
              <el-button class="searchBt" @click="getOwnListEmbedded()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="tab1" style="min-height: auto;">
          <table class="tab">
            <tr class="tab_title">
              <td>
                <p>半屏小程序appid</p>
              </td>
              <td>
                <p>半屏小程序昵称</p>
              </td>
              <td>
                <p>申请状态</p>
              </td>
            </tr>
            <tr v-for="(v, i) in getOwnListData" :key="i">
              <td>
                <p>{{ v.appid }}</p>
              </td>
              <td>
                <p>{{ v.nickname }}</p>
              </td>
              <td>
                <p v-if="v.status == 1">待验证</p>
                <p v-if="v.status == 2">已通过</p>
                <p v-if="v.status == 3">已拒接</p>
                <p v-if="v.status == 4">已超时</p>
                <p v-if="v.status == 5">已撤销</p>
                <p v-if="v.status == 6">已取消授权</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </el-dialog>

    <!-- 设置授权半屏小程序 -->
    <el-dialog :visible.sync="setAuthorizeShow" title="设置授权半屏小程序" width="490px" @close="close">
      <ul class="overAccount_ul">
        <li style="width: 200px; margin: 0 auto">
          <div class="overAccount_ul_title"><i>*</i>半屏小程序授权方式</div>
          <el-select v-model="setembedData.flag">
            <!-- 0表示需要管理员验证；1表示自动通过；2表示自动拒绝 -->
            <el-option label="管理员验证" value="0"></el-option>
            <el-option label="自动通过" value="1"></el-option>
            <el-option label="自动拒绝" value="2"></el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="setAuthorizeShow = false">取 消</el-button>
        <el-button class="addBt" @click="setAuthorizeBtn">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  getApps,
  setAppsStatus,
  isNameCheck,
  getReverify,
  setSyncPrivacy,
  parsingExcelAddData,
  getQr,
  setUser,
  setRules,
  getInquiryCount,
  getCategoryList,
  delAuthorize, ownListEmbedded, setAuthorize
} from "@/api/user/applet.js";
import { URL_API } from "@/utils/apiUrl.js";
import { thirdsDrop } from "@/api/common.js";
import category from "@/utils/category.json";
import * as imageConversion from "image-conversion";
// import firstCategory from "@/utils/firstCategory.json";
// import secondCategory from "@/utils/secondCategory.json";
import thirdCategory from "@/utils/thirdCategory.json";
export default {
  data() {
    return {
      editStatusShow: false, //修改认证状态
      detail: "",
      firstData: [], //一级类目可选项
      twoList: [], //所有二级类目数据
      secondData: [], // 二级类目可选值
      formData: {
        statusType: "",
        appName: "",
        signature: "",
        avatar: "",
        oneId: "", //选中的一级类目id
        twoId: "", //选中的二级类目ID
        commitStatus: "", //代码上传状态码 1 上传成功 0上传失败
        appVersion: "", //代码上传时小程序版本号
        templateId: "", //代码上传时模板ID
        searchStatus: "", // 小程序状态 1 表示不可搜索，0 表示可搜索
        imgUrl: [], //小程序审核时上传的图片
        videoUrl: "", //小程序审核时上传的录屏文件
        wechatid: "", //新增体验者时输入的微信号
      },
      reverifyForm: {
        smallId: "",
        thirdId: "",
        principalName: "",
        enterpriseCodeType: "",
        enterpriseCode: "",
        legalPersonName: "",
        legalPersonWechat: "",
        appId: "",
      },
      appNameStatus: {
        null: "",
        PENDING: "审核中",
        FAIL: "审核失败",
        SUCCESS: "审核成功",
      },
      auditStatus: {
        null: "未审核",
        PENDING: "审核中",
        FAIL: "审核失败",
        SUCCESS: "审核成功",
      },
      uploadUrl: URL_API.upload,
      category,

      thirdCategory,
      thirdsDrop: [],

      countForm: {
        //审核次数数据
        limit: "", //当月分配可以提审次数
        rest: "", //剩余可提审次数
        speedup_limit: "", //当月分配加急次数
        speedup_rest: "", //剩余可加急次数
      },
      imageUrls: [], //存放回显的图片数组

      videoFlag: false, //控制进度条
      videoUploadPercent: 0, //视频上传进度值
      timer: null,

      dialogVisible: false,

      appletStatusList: [
        //小程序状态可选值
        {
          label: "可搜索",
          value: 0,
        },
        {
          label: "不可搜索",
          value: 1,
        },
      ],

      appletStatusName: "", //默认展示的小程序状态名称
      privacyDetails: "", //隐私设置详情
      disableConfir: false, //防止重复提交

      newFile: new FormData(), //   1. 定义一个newFile变量（FormData 对象）

      delAuthorizeShow: false,
      embedData: {
        appid: "",
        flag: "",
      },
      getOwnListShow: false,
      embedListData: {
        start: 0,
        num: 10,
      },
      getOwnListData: {},
      setAuthorizeShow: false,
      setembedData: {
        flag: "",
      }
    };
  },
  created() {
    this.getDetail();
    this.getDrop();
  },
  methods: {
    //半屏小程序 start    
    //删除
    delAuthorizeModel() {
      this.delAuthorizeShow = true;
    },
    delAuthorizeBtn() {
      let data = JSON.parse(JSON.stringify(this.embedData));
      if (!data.appid) {
        this.$message.error("半屏小程序appid必填");
        return;
      }
      if (!data.flag) {
        this.$message.error("半屏小程序授权方式必选");
        return;
      }
      delAuthorize(this.detail.smallId, data).then((res) => {
        if (res.resultStatus) {
          console.log(res)
          this.$message.success("删除成功");
          this.delAuthorizeShow = false;
        }
      });
    },
    getOwnListShowModel() {
      console.log(this.detail.smallId)
      this.getOwnListShow = true;
      this.getOwnListEmbedded();
    },
    getOwnListEmbedded() {
      let data = JSON.parse(JSON.stringify(this.embedListData));
      if (!data.start && data.start !== 0) {
        this.$message.error("分页起始值必填");
        return;
      }
      if (!data.num && data.num !== 0) {
        this.$message.error("分页结束值必填");
        return;
      }
      ownListEmbedded(this.detail.smallId, data).then((res) => {
        if (res.resultStatus) {
          //  if(res.resultStatus.errmsg = "ok"){
          this.getOwnListData = res.resultData.res.wxa_embedded_list;
          //  }         
        }
      });
    },
    // 设置
    setAuthorizeModel() {
      this.setAuthorizeShow = true
    },
    setAuthorizeBtn() {
      let data = JSON.parse(JSON.stringify(this.setembedData));
      if (!data.flag) {
        this.$message.error("半屏小程序授权方式必选");
        return;
      }
      setAuthorize(this.detail.smallId, data).then((res) => {
        if (res.resultStatus) {
          console.log(res)
          this.$message.success("设置成功");
          this.setAuthorizeShow = false;
        }
      });
    },
    //半屏小程序 end

    // 获取详情
    getDetail() {
      getApps(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.appletStatusList.forEach((e) => {
            if (e.value == res.resultData.changeStatus) {
              this.appletStatusName = e.label;
            }
          });
          if (res.resultData.changeStatus == 1) {
            this.privacyDetails = "不可搜索";
          } else if (res.resultData.changeStatus == 0) {
            this.privacyDetails = "可搜索";
          } else {
            this.privacyDetails = "";
          }
          this.detail = res.resultData;
          console.log("配置信息", this.detail);

          if (this.detail.verifyStatus) { //  && this.detail.appType != "MERCHANT"
            this._getAllCategoryData(this.$route.query.id); //如果已认证则去获取所有类目数据
          }
        }
      });
    },
    // 获取下拉
    getDrop() {
      thirdsDrop().then((res) => {
        if (res) {
          this.thirdsDrop = res.resultData;
        }
      });
    },

    // 获取体验版二维码
    getQrCode() {
      let params = {
        id: this.detail.smallId,
      };
      getQr(params).then((data) => {
        if (!data) {
          this.$message.error("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data]),
            this.detail.appName + "(体验版)" + ".jpg"
          );
        } else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute(
            "download",
            this.detail.appName + "(体验版)" + ".jpg"
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },

    // 确定新增体验者 setRules
    saveUser() {
      // this.disableConfir = true;
      let params = {
        id: this.detail.smallId,
        wechatid: this.formData.wechatid,
      };
      setUser(params).then((res) => {
        if (res.resultStatus) {
          this.formData.statusType = "";
          this.getDetail();
          // this.disableConfir = false;
        } else {
          // this.disableConfir = true;
        }
      });
    },

    // 设置规则
    saveRules() {
      let params = {
        id: this.detail.smallId,
      };
      setRules(params).then((res) => {
        if (res.resultStatus) {
          this.$message.success("设置规则成功");
        }
      });
    },

    // 代码审核提交时查询次数
    getCount() {
      let params = {
        id: this.detail.smallId,
      };
      getInquiryCount(params).then((res) => {
        console.log("查询次数", res);
        if (res.resultStatus) {
          this.$message.success("查询成功");
          this.countForm.limit = res.resultData.res.limit;
          this.countForm.rest = res.resultData.res.rest;
          this.countForm.speedup_limit = res.resultData.res.speedup_limit;
          this.countForm.speedup_rest = res.resultData.res.speedup_rest;
        }
      });
    },

    // 同步
    sync(name) {
      this.formData = {
        statusType: name,
        appName: this.detail.appName,
        signature: this.detail.signature,
        avatar: this.detail.avatar,
        firstCategory: this.detail.firstCategory,
        secondCategory: this.detail.secondCategory,
        thirdCategory: this.detail.thirdCategory,
        appVersion: this.detail.appVersion,
        commitStatus: this.detail.commitStatus,
        searchStatus: this.detail.changeStatus,
      };
      setAppsStatus(this.$route.query.id, this.formData).then((res) => {
        if (res.resultStatus) {
          this.$message.success("同步请求成功");
          this.getDetail();
        }
      });
    },
    // 重新认证展示
    setReverifyShow() {
      if (this.detail.verifyStatus) {
        return;
      }
      this.editStatusShow = true;
      this.reverifyForm = {
        smallId: "",
        thirdId: "",
        principalName: "",
        enterpriseCodeType: "",
        enterpriseCode: "",
        legalPersonName: "",
        legalPersonWechat: "",
        appId: "",
      };
    },
    // 重新认证
    setReverify() {
      let data = JSON.parse(JSON.stringify(this.reverifyForm));
      if (!data.enterpriseCodeType && this.detail.appType !== "REUSE") {
        this.$message.error("企业代码类型必选");
        return false;
      }
      if (!data.thirdId) {
        this.$message.error("所属第三方必选");
        return false;
      }
      if (!data.appId && this.detail.appType === "REUSE") {
        this.$message.error("APPID必填");
        return false;
      }
      if (!data.enterpriseCode && this.detail.appType !== "REUSE") {
        this.$message.error("企业代码必填");
        return false;
      }
      if (
        data.enterpriseCodeType == 1 &&
        data.enterpriseCode.length != 18 &&
        this.detail.appType !== "REUSE"
      ) {
        this.$message.error("企业代码为18位");
        return false;
      }
      if (
        data.enterpriseCodeType == 2 &&
        !/^\S{8}-\S{1}$/.test(data.enterpriseCode) &&
        this.detail.appType !== "REUSE"
      ) {
        this.$message.error("企业代码为9位(xxxxxxxx-x)");
        return false;
      }
      if (
        data.enterpriseCodeType == 3 &&
        data.enterpriseCode.length != 15 &&
        this.detail.appType !== "REUSE"
      ) {
        this.$message.error("企业代码为15位");
        return false;
      }
      data.smallId = this.detail.smallId;
      data.principalName = this.detail.principalName;
      getReverify(data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("修改认证请求成功");
          this.getDetail();
          this.editStatusShow = false;
        }
      });
    },
    // 修改展示
    editTabShow(name) {
      if (name == "AUDIT") {
        this.imageUrls = [];

        for (var i in this.countForm) {
          this.countForm[i] = "";
        }
      }
      // 清空二级类目可选项
      this.secondData = [];
      // 1,拿到一级类目
      let oneId = "";
      this.firstData.forEach((e) => {
        if (e.name == this.detail.firstCategory) {
          oneId = e.id;
        }
      });
      // 2,根据一级类目id获取二级类目可选项
      this.twoList.forEach((e) => {
        if (e.fatherId == oneId) {
          this.secondData.push(e);
        }
      });
      // 拿到二级类目id
      let twoId = "";
      // console.log("aaaaaaaaaa", this.secondData);
      this.secondData.forEach((e) => {
        if (e.name == this.detail.secondCategory) {
          twoId = e.id;
        }
      });

      this.formData = {
        statusType: name,
        appName: this.detail.appName,
        signature: this.detail.signature,
        avatar: this.detail.avatar,
        oneId,
        twoId,
        thirdCategory: "",
        appVersion: this.detail.appVersion,
        imgUrl: [],
        videoUrl: "", //小程序审核时上传的录屏文件
        wechatid: "", //每次弹窗清空输入的验证微信号
      };
      // console.log("弹窗之后的数据", this.formData);

      // this.cateChange(3);
    },
    // 名字重复
    isName() {
      if (this.formData.appName) {
        let data = {
          appName: this.formData.appName,
        };
        isNameCheck(this.$route.query.id, data).then((res) => {
          console.log("名称可用", res);
          if (res.resultStatus) {
            this.$message.success("名称可用");
          }
        });
      } else {
        this.$message.error("请输入小程序名称");
      }
    },

    // 请求所有类目数据
    _getAllCategoryData(smallId) {
      let params = {
        id: smallId,
      };
      getCategoryList(params).then((res) => {
        if (res.resultStatus) {
          const arry = res.resultData.res.categories_list.categories;

          arry.forEach((e) => {
            if (e.level == 1) {
              this.firstData.push({
                id: e.id,
                name: e.name,
              });
            } else if (e.level == 2) {
              this.twoList.push({
                id: e.id,
                name: e.name,
                fatherId: e.father,
              });
            }
          });
          // console.log("所有类目数据", arry);
          // console.log("一级类目数据", this.firstData);
          // console.log("所有二级类目数据", this.twoList);
        }
      });
    },
    // 头像上传
    avatarSuccess(res) {
      if (res.resultStatus) {
        this.formData.avatar = res.resultData.fileLink;
      }
    },

    // 新增品牌->类目选中的值发生变化时
    cateChange(val) {
      if (val == 1) {
        // 一级类目的选中值发生变化时
        this.secondData = []; //清空二级类目可选项
        this.formData.twoId = ""; //清空选中的二级类目

        // 根据选中的一级类目id 获取二级类目可选项
        this.twoList.forEach((e) => {
          if (e.fatherId == this.formData.oneId) {
            this.secondData.push(e);
          }
        });
        // console.log(
        //   "一级类目改变后获取到的二级类目可选项数据为",
        //   this.secondData
        // );
      }
    },

    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "gif" ||
        testmsg === "JIF" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png/bpm/gif格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
    // 表单规则
    roles() {
      let data = JSON.parse(JSON.stringify(this.formData));
      // console.log("datadatadatadatadatadatadatadatadata",data)
      // return
      if (data.statusType == "APPNAME" && !data.appName) {
        this.$message.error("小程序名称必填");
        return false;
      } else if (data.statusType == "SIGNATURE" && !data.signature) {
        this.$message.error("功能介绍必填");
        return false;
      } else if (data.statusType == "AVATAR" && !data.avatar) {
        this.$message.error("头像必传");
        return false;
      } else if (data.statusType == "CATEGORY" && !data.oneId) {
        this.$message.error("一级类目必选");
        return false;
      } else if (data.statusType == "CATEGORY" && !data.twoId) {
        this.$message.error("二级类目必选");
        return false;
      } else if (data.statusType == "COMMIT") {
        if (!data.appVersion || !data.templateId) {
          this.$message.error("版本号和模板ID必填");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 确定修改
    editTab() {
      if (!this.roles()) {
        return;
      }

      this.disableConfir = true;
      this.firstData.forEach((e) => {
        if (e.id == this.formData.oneId) {
          this.formData.firstCategory = e.name;
        }
      });
      // 拿到二级类目的类目名
      this.secondData.forEach((e) => {
        if (e.id == this.formData.twoId) {
          this.formData.secondCategory = e.name;
        }
      });
      // console.log("最终提交的数据", this.formData)
      // return
      setAppsStatus(this.$route.query.id, this.formData).then((res) => {
        console.log("提交", res);
        if (res.resultStatus) {
          if (this.formData.statusType == "COMMIT") {
            this.$message.success("上传成功");
          } else if (this.formData.statusType == "AUDIT") {
            this.$message.success("提审成功");
          } else if (this.formData.statusType == "SEARCH") {
            this.$message.success("设置成功");
          } else {
            this.$message.success("保存成功");
          }

          this.formData.statusType = "";
          this.getDetail();
          this.disableConfir = false;
        } else {
          // this.$message.error(res.errorMessage);
          this.formData.statusType = "";
          this.disableConfir = false;
        }
      });
    },

    roles2() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (
        data.statusType == "AUDIT" &&
        data.imgUrl.length == 0 &&
        !data.videoUrl
      ) {
        this.$message.error("请上传图片或视频");
        return false;
      }
      return true;
    },
    // 加急审核
    expeditedReview() {
      if (!this.roles2()) {
        return;
      }
      this.disableConfir = true;
      this.formData.statusType = "SPEEDUPAUDIT";
      // console.log("提交", this.formData);
      // return;
      setAppsStatus(this.$route.query.id, this.formData).then((res) => {
        console.log("提交", res);
        if (res.resultStatus) {
          this.formData.statusType = "";
          this.getDetail();
          this.disableConfir = false;
        } else {
          // this.$message.error(res.errorMessage);
          this.formData.statusType = "";
          this.disableConfir = false;
        }
      });
    },

    //同步隐私设置
    setSync() {
      setSyncPrivacy(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("同步设置成功");
          this.getDetail();
        }
      });
    },
    // 关闭弹窗
    close() {
      this.formData.statusType = "";
      this.embedData = {};
      this.embedListData = {
        start: 0,
        num: 10,
      },
        this.setembedData = {};
    },
    back() {
      this.$router.push("/user/add_agentlist_xx");
    },
    next() {
      this.$router.push("/user/add_agentlist_cp");
    },
    exitTypeID() {
      this.$confirm("是否撤销当前版本", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formData.statusType = "AUDIT_REVOKE";
          setAppsStatus(this.$route.query.id, this.formData).then((res) => {
            if (res) {
              this.getDetail();
            }
          });
        })
        .catch(() => { });
    },
    // 部署
    okTypeCode() {
      console.log("代码审核状态为", this.detail.auditStatus);
      // return
      if (this.detail.auditStatus == "SUCCESS") {
        this.$confirm("是否部署当前版本", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.formData.statusType = "DEPLOY";
            setAppsStatus(this.$route.query.id, this.formData).then((res) => {
              if (res.resultStatus) {
                this.$message.success("部署成功");
                this.getDetail();
              }
            });
          })
          .catch(() => { });
      } else {
        this.$message.error("请等待通过审核");
      }
    },
    // 回退
    exitTypeCode() {
      this.$confirm("是否回退版本", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formData.statusType = "DEPLOY_REVOKE";
          setAppsStatus(this.$route.query.id, this.formData).then((res) => {
            if (res.resultStatus) {
              this.$message.success("回退成功");
              this.getDetail();
            }
          });
        })
        .catch(() => { });
    },
    overType() {
      if (this.detail.verifyStatus) {
        return;
      }
      this.$confirm("是否通过当前认证", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formData.statusType = "VERIFY";
          setAppsStatus(this.$route.query.id, this.formData).then((res) => {
            if (res) {
              this.getDetail();
            }
          });
        })
        .catch(() => { });
    },
    overType2() {
      let str = this.detail.deployStatus ? "撤销" : "部署";
      this.$confirm(`是否${str}当前版本`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formData.statusType = "DELOPY_STATUS_SWITCH";
          setAppsStatus(this.$route.query.id, this.formData).then((res) => {
            if (res) {
              this.getDetail();
            }
          });
        })
        .catch(() => { });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          status: this.$route.query.status,
        },
      });
    },

    // 新增逻辑 start -------------------------------------------------------

    // 代码审核 上传截图成功
    auditImgSuccess(res) {
      if (res.resultStatus) {
        let that = this;
        that.formData.imgUrl.push(res.resultData.fileLink);

        console.log(
          "图片上传成功",
          JSON.parse(JSON.stringify(that.formData.imgUrl))
        );
      }
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.imageUrls = file.url;
      this.dialogVisible = true;
    },

    //图片移除
    handleImgRemove(file, fileList) {
      console.log("移除的照片id", file);
      // console.log("移除的照片id", fileList);

      let imgFlag = file.response.resultData.fileLink;

      // console.log("移除的照片id", imgFlag);

      this.formData.imgUrl = this.formData.imgUrl.filter((item) => {
        //将要删除的当前图片的标识id与返回的图片标识id数组进行过滤筛选
        return item != imgFlag;
      });

      console.log("移除后的照片id", this.formData.imgUrl);
    },
    //视频上传成功回调
    handleVideoSuccess(res, file) {
      if (res.resultStatus) {
        this.videoFlag = false;
        this.videoUploadPercent = 0;

        clearInterval(this.timer);
        this.timer = null;

        this.formData.videoUrl = res.resultData.fileLink;

        console.log("视频上传成功", this.formData.videoUrl);
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },
    //视频文件覆盖
    handleChange(files, fileList) {
      console.log("上传视频", files);
      if (fileList.length > 1) {
        this.videoUploadPercent = 0;
        fileList.splice(0, 1);
      }
    },
    //视频上传进度
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      // this.videoUploadPercent = 1;

      // this.timer = setTimeout(() => {
      //   this.videoUploadPercent += 10;
      //   if (this.videoUploadPercent >= 100) {
      //     console.log("终止定时器", this.videoUploadPercent);

      //     clearTimeout(this.timer);
      //   }
      // }, 1000);

      this.timer = setInterval(() => {
        this.videoUploadPercent += 12;
        if (this.videoUploadPercent >= 100) {
          // clearInterval(this.timer);
          // this.timer=null
          this.videoUploadPercent = 100;
        }
      }, 4000);
    },

    //控制视频上传格式
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过10MB!");
        return false;
      }
    },

    //修改隐私设置
    changeAppletStatus(e) {
      console.log("修改隐私设置", e);
      if (e == 1) {
        this.appletStatusName = "不可搜索";
      } else {
        this.appletStatusName = "可搜索";
      }
      this.formData.searchStatus = e;
    },
  },
};
</script>
<style scoped>
.con_from .lineP .item>i {
  width: 80px;
}

.con_from .lineP .item {
  margin-left: 5%;
}

.con_from .lineP .item:first-child {
  margin-left: 0%;
}

/deep/ .el-upload-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  background: rgba(246, 246, 246, 1);
}

.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}


.lianjie {
  color: #008aff;
  padding: 0 5px 0 5px;
  cursor: pointer;
}

.lianjie.over {
  color: #999999;
  cursor: not-allowed;
}

.tab img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.tab tr td p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.tab_title {
  background: rgba(244, 245, 246, 1);
  border-top: 1px solid rgba(220, 224, 230, 1);
}

.tab1 .tab tr {
  padding-left: 20px;
  height: 60px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab1 {
  margin-top: 20px;
  margin-bottom: 18px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}



.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.desc .jsxx {
  font-size: 16px !important;
  font-family: PingFang SC !important;
  font-weight: 600 !important;
  color: var(--label) !important;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.progressBox {
  position: absolute;
  top: -32px;
  left: 0;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/* 新增样式 start */

.imgBox {
  display: flex;
}

.videoBox {
  width: 100%;
  height: 100%;
  /* display: inline-block; */
}

/*新增样式 end */

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
</style>
